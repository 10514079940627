$glow-color: #f155e3;
$glow-color-primary: #ff20af;
$glow-color-secondary: #0abab5;

.glowing-light {
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 1), 0px 0px 20px $glow-color,
    0px 0px 30px $glow-color, 0px 0px 40px $glow-color, 0px 0px 55px $glow-color,
    0px 0px 75px $glow-color;

  color: #fff;
}
