#home-container #home-bg {
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, #0a0b0a 0%, #272b27 0%, #0a0b0a 50%);
}

.home-page {
  background-color: $white;
  // height: 70vh;
  // min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: $xxl * 2;
  padding-top: $xxl * 3;
}

.mobile-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  top: 0;
  left: 0;
  background-position: center;
  // min-height: 600px;
}

.centered-axis-xy {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#bg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

video {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  background-color: $white;
  opacity: 0.15;
  z-index: 1;
  min-height: 600px;
}

#home-container {
  #bg,
  .overlay {
    height: 80vh;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1024px) {
  .home-page,
  .mobile-bg,
  .video-container,
  #bg,
  .overlay {
    // height: 90vh;
    min-height: 600px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 10%;
  }
  50% {
    background-position: 100% 91%;
  }
  100% {
    background-position: 0% 10%;
  }
}
