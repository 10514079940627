@font-face {
  font-family: "Jaturat DEMO";
  src: url("./fonts/cf3fb387331afda8f5d7f9c8dea3cd13.eot");
  src: url("./fonts/cf3fb387331afda8f5d7f9c8dea3cd13.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/cf3fb387331afda8f5d7f9c8dea3cd13.woff2") format("woff2"),
    url("./fonts/cf3fb387331afda8f5d7f9c8dea3cd13.woff") format("woff"),
    url("./fonts/cf3fb387331afda8f5d7f9c8dea3cd13.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$logoFamily: "Montserrat", sans-serif;
$titleFmaily: "Jaturat DEMO";
$subTitleFmaily: "Jaturat DEMO";
$txtFamily: "Jaturat DEMO";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;

h1,
h2,
h3,
h4,
h5 {
  color: $txt;
  font-weight: 500;
}

h1 {
  font-family: $logoFamily;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
}

h2 {
  font-family: $titleFmaily;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
}

h4,
h5 {
  font-family: $subTitleFmaily;
  letter-spacing: 0.5px;
}

p,
li,
a,
pre {
  font-family: $txtFamily;
  letter-spacing: 0px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0px;
  font-size: 20px;
}

.button-txt {
  font-family: "Times-new-roman";
  font-size: 28px;
  letter-spacing: 1.5px;
  margin-top: 18px;
}

.ant-notification-notice-description,
.ant-notification-notice-message {
  font-family: $txtFamily;
}

.form-text {
  color: grey;
}

.rotated {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.rotated-invert {
  writing-mode: vertical-rl;
  transform: rotate(0);
}

.txt-gradient {
  background: -webkit-linear-gradient(left, $primary, $secondary);
  background: -o-linear-gradient(right, $primary, $secondary);
  background: -moz-linear-gradient(right, $primary, $secondary);
  background: linear-gradient(to right, $primary, $secondary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-dark-gradient {
  background: -webkit-linear-gradient(left, $primary-dark, $secondary);
  background: -o-linear-gradient(right, $primary-dark, $secondary);
  background: -moz-linear-gradient(right, $primary-dark, $secondary);
  background: linear-gradient(to right, $primary-dark, $secondary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) {
  .rotated {
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }
}
